import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import { IconStickyLarge } from '@src/routes/common/icons/IconStickyLarge';
import { IconStickyMedium } from '@src/routes/common/icons/IconStickyMedium';
import { IconStickySmall } from '@src/routes/common/icons/IconStickySmall';

import styles from './styles.module.css';

interface HaldiStickyNoteProps {
  children: React.ReactNode;
  className?: string;
  flipped?: boolean;
  fill?: string;
  rotate?: number;
  size?: 'small' | 'medium' | 'large';
}

/**
 * @name HaldiStickyNote
 * @description This component is used to render our sticky notes (think post-it).
 * We support three sizes: small, medium and large which can be scaled up or down
 * as they use SVG's. We can also rotate and flip them as needed, pretty flexible.
 */
const HaldiStickyNote = (props: HaldiStickyNoteProps) => {
  const {
    children,
    className,
    fill = color.turmeric,
    flipped,
    rotate = 3,
    size = 'large'
  } = props;

  // Hooks
  const refSticky = React.useRef<HTMLDivElement>(null);

  // Setup
  let Component = IconStickySmall;

  if (size === 'small') Component = IconStickySmall;
  if (size === 'medium') Component = IconStickyMedium;
  if (size === 'large') Component = IconStickyLarge;

  // Styles
  const cssComponent = classnames(styles.component, className, {
    [styles.flipped]: flipped
  });

  return (
    <div
      className={cssComponent}
      ref={refSticky}
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <div className={styles.contents}>{children}</div>
      <Component className={styles.image} fill={fill} />
    </div>
  );
};

export { HaldiStickyNote };
export type { HaldiStickyNoteProps };
